import React, { useEffect, useState } from "react";
import "./index.css";
const HeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 0); // Adjust the threshold as needed
    };

    window.addEventListener("scroll", handleScroll);

    // Set initial state based on initial scroll position
    setIsVisible(window.scrollY >= 0); // Adjust the threshold as needed

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = (e) => {
      if (!isVisible && window.scrollY < 10) {
        e.preventDefault();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible]);

  useEffect(() => {
    const preloader = document.querySelector("#preloader");
    if (preloader) {
      window.addEventListener("load", () => {
        preloader.remove();
      });
    }

    const selectHeader = document.querySelector("#header");
    if (selectHeader) {
      document.addEventListener("scroll", () => {
        window.scrollY > 100
          ? selectHeader.classList.add("sticked")
          : selectHeader.classList.remove("sticked");
      });
    }

    const mobileNavToggle = () => {
      const mobileNavShow = document.querySelector(".mobile-nav-show");
      const mobileNavHide = document.querySelector(".mobile-nav-hide");

      document.querySelectorAll(".mobile-nav-toggle").forEach((el) => {
        el.addEventListener("click", function (event) {
          event.preventDefault();
          document.body.classList.toggle("mobile-nav-active");
          mobileNavShow.classList.toggle("d-none");
          mobileNavHide.classList.toggle("d-none");
        });
      });
    };

    mobileNavToggle();

    const navDropdowns = document.querySelectorAll(".navbar .dropdown > a");
    navDropdowns.forEach((el) => {
      el.addEventListener("click", function (event) {
        if (document.querySelector(".mobile-nav-active")) {
          event.preventDefault();
          this.classList.toggle("active");
          this.nextElementSibling.classList.toggle("dropdown-active");

          let dropDownIndicator = this.querySelector(".dropdown-indicator");
          dropDownIndicator.classList.toggle("bi-chevron-up");
          dropDownIndicator.classList.toggle("bi-chevron-down");
        }
      });
    });

    return () => {
      window.removeEventListener("load", () => {
        preloader.remove();
      });
      window.removeEventListener("scroll", () => {
        window.scrollY > 100
          ? selectHeader.classList.add("sticked")
          : selectHeader.classList.remove("sticked");
      });
      document.querySelectorAll(".mobile-nav-toggle").forEach((el) => {
        el.removeEventListener("click", mobileNavToggle);
      });
      navDropdowns.forEach((el) => {
        el.removeEventListener("click", function (event) {
          if (document.querySelector(".mobile-nav-active")) {
            event.preventDefault();
            this.classList.toggle("active");
            this.nextElementSibling.classList.toggle("dropdown-active");

            let dropDownIndicator = this.querySelector(".dropdown-indicator");
            dropDownIndicator.classList.toggle("bi-chevron-up");
            dropDownIndicator.classList.toggle("bi-chevron-down");
          }
        });
      });
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <section
      id="hero"
      className={`hero d-flex align-items-center ${
        isVisible ? "active" : ""
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-4">
            <h2 data-aos="fade-up">Your Partner in UK Immigration</h2>
            <blockquote data-aos="fade-up" data-aos-delay="100">
              <p>
                Welcome to <strong>Hunter Stone Law,</strong> your premier
                destination for all matters related to UK immigration, asylum,
                and nationality law.
              </p>
            </blockquote>
            <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
              <a href="#about-us" className="btn-get-started">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
