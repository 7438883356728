import React, { useEffect, useState } from "react";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 100);

      const aboutSection = document.getElementById("about-us");
      const servicesSection = document.getElementById("services-list");
      const contactSection = document.getElementById("contact");

      const offset = 100; // Adjust this offset as needed

      if (
        aboutSection &&
        window.scrollY >= aboutSection.offsetTop - offset &&
        window.scrollY < servicesSection.offsetTop - offset
      ) {
        setActiveSection("about-us");
      } else if (
        servicesSection &&
        window.scrollY >= servicesSection.offsetTop - offset &&
        window.scrollY < contactSection.offsetTop - offset
      ) {
        setActiveSection("services-list");
      } else if (
        contactSection &&
        window.scrollY >= contactSection.offsetTop - offset
      ) {
        setActiveSection("contact");
      } else {
        setActiveSection("");
      }
    };

    document.addEventListener("scroll", handleScroll);

    // Cleanup function for removing event listener when component unmounts
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isMobileNavActive, setIsMobileNavActive] = useState(false);

  useEffect(() => {
    const handleMobileNavToggle = () => {
      setIsMobileNavActive((prevState) => !prevState);
    };

    const closeMobileNav = () => {
      setIsMobileNavActive(false);
    };

    document.querySelectorAll(".mobile-nav-toggle").forEach((el) => {
      el.addEventListener("click", handleMobileNavToggle);
    });

    document.querySelectorAll(".mobile-nav-close").forEach((el) => {
      el.addEventListener("click", closeMobileNav);
    });

    // Cleanup function for removing event listeners when component unmounts
    return () => {
      document.querySelectorAll(".mobile-nav-toggle").forEach((el) => {
        el.removeEventListener("click", handleMobileNavToggle);
      });

      document.querySelectorAll(".mobile-nav-close").forEach((el) => {
        el.removeEventListener("click", closeMobileNav);
      });
    };
  }, []);

  return (
    <header
      className={`header d-flex align-items-center ${
        isSticky ? "sticked" : ""
      } ${isMobileNavActive ? "mobile-nav-active" : ""} fixed-top`}
    >
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <a href="index.html" className="logo d-flex align-items-center">
          <img src="assets/img/logo.png" alt="Hunter Stone Law" />
        </a>

        <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
        <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <a
                href="#"
                className={activeSection === "home" ? "active" : ""}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#about-us"
                className={activeSection === "about-us" ? "active" : ""}
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#services-list"
                className={activeSection === "services-list" ? "active" : ""}
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className={activeSection === "contact" ? "active" : ""}
              >
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
