import React from "react";

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="footer-content">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <a href="index.html" className="logo d-flex align-items-center">
                <img src="assets/img/logo.png" alt="" />
              </a>
              <p>
                If you would like more information about any of our services or
                to speak directly to a specialist consultant, please contact us.
              </p>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bi bi-dash"></i> <a href="#hero">Home</a>
                </li>
                <li>
                  <i className="bi bi-dash"></i>{" "}
                  <a href="#about-us">About us</a>
                </li>
                <li>
                  <i className="bi bi-dash"></i>{" "}
                  <a href="#services-list">Services</a>
                </li>
                <li>
                  <i className="bi bi-dash"></i>{" "}
                  <a href="#contact">Contact Us</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bi bi-dash"></i>{" "}
                  <a href="#services-list">Telephone Advice</a>
                </li>
                <li>
                  <i className="bi bi-dash"></i>{" "}
                  <a href="#services-list">Application Checking</a>
                </li>
                <li>
                  <i className="bi bi-dash"></i>{" "}
                  <a href="#services-list">Corporate Services</a>
                </li>
                <li>
                  <i className="bi bi-dash"></i>{" "}
                  <a href="#services-list">Application Package</a>
                </li>
                <li>
                  <i className="bi bi-dash"></i>{" "}
                  <a href="#services-list">Fixed Fee Consultation</a>
                </li>
                <li>
                  <i className="bi bi-dash"></i>{" "}
                  <a href="#services-list">Work Place Visits</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <p>
                518 Roman Road, <br />
                London,
                <br />
                E3 5ES
              </p>
              <p>
                <strong>Phone:</strong>
                <br /> +44 20 3137 9097
                <br />
                <br />
                <strong>Email:</strong>
                <br /> info@hunterstonelaw.com
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-legal">
        <div className="container">
          <div className="copyright">
            <p>&copy; Copyright{" "}
            <strong>
              <span>Hunter Stone Law</span>
            </strong>
            . All Rights Reserved</p>
            <p><strong>Hunter Stone Law is the trading name of CK & Co Legal Services Ltd.</strong></p>
          </div>
          
        </div>
      </div>
    </footer>
  );
};

export default Footer;
