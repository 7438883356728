import { Fragment, useEffect, useState } from "react";
import AboutUsSection from "./components/about-us";
import Contact from "./components/contact-us";
import Footer from "./components/footer";
import Header from "./components/header";
import HeroSection from "./components/hero-section";
import ServicesList from "./components/services";
import Isotope from 'isotope-layout';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./App.css";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "slide",
      once: true,
      mirror: false,
    });

    return () => {
      AOS.refresh(); // Refresh AOS when the component unmounts
    };
  }, []);

  useEffect(() => {
    let portfolionIsotope = document.querySelector('.portfolio-isotope');
  
    if (portfolionIsotope) {
      let portfolioFilter = portfolionIsotope.getAttribute('data-portfolio-filter') ? portfolionIsotope.getAttribute('data-portfolio-filter') : '*';
      let portfolioLayout = portfolionIsotope.getAttribute('data-portfolio-layout') ? portfolionIsotope.getAttribute('data-portfolio-layout') : 'masonry';
      let portfolioSort = portfolionIsotope.getAttribute('data-portfolio-sort') ? portfolionIsotope.getAttribute('data-portfolio-sort') : 'original-order';
  
      window.addEventListener('load', () => {
        let portfolioIsotope = new Isotope(document.querySelector('.portfolio-container'), {
          itemSelector: '.portfolio-item',
          layoutMode: portfolioLayout,
          filter: portfolioFilter,
          sortBy: portfolioSort
        });
  
        let menuFilters = document.querySelectorAll('.portfolio-isotope .portfolio-flters li');
        menuFilters.forEach(function(el) {
          el.addEventListener('click', function() {
            document.querySelector('.portfolio-isotope .portfolio-flters .filter-active').classList.remove('filter-active');
            this.classList.add('filter-active');
            portfolioIsotope.arrange({
              filter: this.getAttribute('data-filter')
            });
          }, false);
        });
      });
    }
  }, []); // Empty dependency array ensures the effect runs only once after component mount

  useEffect(() => {
    const preloader = document.querySelector("#preloader");
    if (preloader) {
      const handleLoad = () => {
        setIsLoading(false);
      };
      
      window.addEventListener("load", handleLoad);

      // Set a timeout to ensure the preloader hides in case the load event is missed
      const loadTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 1000); // Adjust the timeout duration as needed

      return () => {
        window.removeEventListener("load", handleLoad);
        clearTimeout(loadTimeout);
      };
    }
  }, []);

  return (
    <div>
      {isLoading ? (
        <div id="preloader"></div>
      ) : (
        <Fragment>
          {/* <div className="page-index"> */}
            <Header />
            <HeroSection />
            <main id="main">
              <AboutUsSection />
              <ServicesList />
              <Contact />
              <Footer />
            </main>
          {/* </div> */}
        </Fragment>
      )}
    </div>
  );
}

export default App;
