import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "./index.css";
import AOS from "aos";
import "aos/dist/aos.css";

SwiperCore.use([Pagination, Autoplay]);
const AboutUsSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "slide",
      once: true,
      mirror: false,
    });

    return () => {
      AOS.refresh(); // Refresh AOS when the component unmounts
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 10);
    };

    window.addEventListener("scroll", handleScroll);

    setIsVisible(window.scrollY > 10);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section id="about-us" className={`about-us ${isVisible ? "active" : ""}`}>
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>About Us</h2>
        </div>
        <div className="row g-0" data-aos="fade-up" data-aos-delay="200">
          <div className="col-xl-5 img-bg"></div>
          <div className="col-xl-7 slides position-relative">
            <Swiper
              loop={true}
              onSwiper={(swiper) => setSwiper(swiper)}
              className="swiper"
              speed={600}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              autoplay={{ delay: 5000 }}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{
                clickable: true,
                type: "bullets",
                el: ".swiper-pagination",
              }}
            >
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <div className="item">
                    <h3 className="mb-3">
                      Hunter Stone Law's Premier Services in London
                    </h3>
                    <h4 className="mb-3">
                      Your Expert Guide to UK Immigration, Asylum, and
                      Nationality Law.
                    </h4>
                    <p>
                      Welcome to Hunter Stone Law, your premier destination for
                      all matters related to UK immigration, asylum, and
                      nationality law. Based in the heart of London, we are an
                      experienced, dedicated team of legal experts who take
                      immense pride in offering our clients expert advice and
                      representation, whether they're wishing to travel to the
                      UK or are already based here and seeking to apply for
                      visas.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="item">
                    <h3 className="mb-3">
                      Tailored Solutions from Hunter Stone Law
                    </h3>
                    <h4 className="mb-3">
                      Compassionate and Comprehensive Immigration Support.
                    </h4>
                    <p>
                      We understand that immigration matters can be complex,
                      often overwhelming, and are deeply personal. That's why
                      our approach is underpinned by a commitment to providing
                      personalised service, grounded in a comprehensive
                      understanding of the UK’s immigration laws and
                      regulations.
                    </p>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="item">
                    <h3 className="mb-3">
                      Hunter Stone Law's Dedication to Client Success
                    </h3>
                    <h4 className="mb-3">
                      Expert Handling Across Visa Applications to Asylum Cases.
                    </h4>
                    <p>
                      Our team at Hunter Stone Law are highly experienced in
                      dealing with a diverse range of cases, from
                      straight-forward visa applications to complex asylum
                      cases. Regardless of the complexity, we treat each case
                      with the same diligence, ensuring the best possible
                      outcome for our clients.
                    </p>
                  </div>
                </SwiperSlide>
              </div>
              <div className="swiper-pagination"></div>
            </Swiper>
            <div
              className="swiper-button-prev"
              onClick={() => swiper && swiper.slidePrev()}
            ></div>
            <div
              className="swiper-button-next"
              onClick={() => swiper && swiper.slideNext()}
            ></div>
          </div>
        </div>
        <section id="services-cards" className="services-cards">
          <div className="container" data-aos="fade-up">
            <div className="row gy-4">
              <div
                className="col-lg-12"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="card-item">
                  <div className="row">
                    <div className="col-xl-4">
                      <img
                        className="card-img"
                        src="assets/img/about/1.png"
                        alt="About 1"
                      />
                    </div>
                    <div className="col-xl-8 d-flex align-items-center">
                      <div className="card-body">
                        <h4 className="card-title">
                          Excellence, Expertise, and Experience
                        </h4>
                        <p>
                          Hunter Stone Law boasts an exceptional track record in
                          providing reliable, professional, and effective legal
                          advice. We are experts in our field, and our team of
                          immigration lawyers comes with years of practical
                          experience in navigating the intricacies of the UK
                          immigration system.
                        </p>
                        <p>
                          Every member of our team is well-versed in all areas
                          of immigration, asylum, and nationality law, enabling
                          us to provide comprehensive legal support to our
                          clients, no matter their needs or circumstances. We
                          are well equipped to handle every scenario, from
                          individual visa applications to corporate immigration
                          services, and complex asylum claims.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <div className="card-item d-flex flex-column h-100">
                  <div className="row">
                    <div className="col-xl-5">
                      <img
                        className="card-img"
                        src="assets/img/about/2.png"
                        alt="About 2"
                      />
                    </div>
                    <div className="col-xl-7 d-flex align-items-center">
                      <div className="card-body">
                        <h4 className="card-title">Regulated and Reputable</h4>
                        <p>
                          We are proudly regulated by the Office of the
                          Immigration Services Commissioner (OISC), a clear
                          testament to our adherence to high standards of advice
                          and service. This also means we are fully accountable,
                          providing our clients with additional peace of mind
                          when they entrust their immigration matters to us.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6"data-aos="fade-up" data-aos-delay="300">
                <div className="card-item d-flex flex-column h-100">
                  <div className="row">
                    <div className="col-xl-5">
                      <img
                        className="card-img"
                        src="assets/img/about/3.png"
                        alt="About 3"
                      />
                    </div>
                    <div className="col-xl-7 d-flex align-items-center">
                      <div className="card-body">
                        <h4 className="card-title">Client-Centred Approach</h4>
                        <p>
                          At Hunter Stone Law, our clients are at the centre of
                          everything we do. We are committed to offering a
                          service that is not only professional and efficient
                          but also personal and tailored to the unique needs of
                          each individual we serve. Our team takes the time to
                          understand your situation in-depth and present you
                          with a range of viable options to consider.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12"data-aos="fade-up"  data-aos-delay="300">
                <div className="card-item">
                  <div className="row">
                    <div className="col-xl-4">
                      <img
                        className="card-img"
                        src="assets/img/about/4.png"
                        alt="About 4"
                      />
                    </div>
                    <div className="col-xl-8 d-flex align-items-center">
                      <div className="card-body">
                        <h4 className="card-title">Your Future, Our Focus</h4>
                        <p>
                          Whether you're an individual seeking to extend your
                          stay in the UK, a business looking to recruit from
                          overseas, or someone seeking asylum, our goal is to
                          secure your future and realise your potential within
                          the framework of UK immigration law. At Hunter Stone
                          Law, your future is our focus.
                        </p>
                        <p>
                          We look forward to the opportunity of working with you
                          to help you navigate your immigration journey in the
                          UK. For more information about our services or to
                          schedule a consultation, please contact us today.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default AboutUsSection;
