import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
emailjs.init("Sa5hCK3uPsTTlQ12_");
const Contact = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "slide",
      once: true,
      mirror: false,
    });

    return () => {
      AOS.refresh(); // Refresh AOS when the component unmounts
    };
  }, []);

  const onSubmit = async (data, e) => {
    console.log(data);
    await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data, null, 2),
    })
      .then(async (response) => {
        let json = await response.json();
        if (json.success) {
          setIsSuccess(true);
          setResult(json.message);
          e.target.reset();
          reset();
        } else {
          setIsSuccess(false);
          setResult(json.message);
          e.target.reset();
        }
      })
      .catch((error) => {
        setIsSuccess(false);
        setResult("Client Error. Please check the console.log for more info");
        e.target.reset();
        console.log(error);
      });
  };

  return (
    <section id="contact" className="contact mt-5">
      <div className="container position-relative" data-aos="fade-up">
        <div className="section-header">
          <h2>Contact Us</h2>
        </div>
        <div className="row gy-4 d-flex justify-content-end">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="250">
          <form
            action="forms/contact.php"
            method="post"
            className="php-email-form"
            data-aos="fade-up"
            data-aos-delay="400"
            onSubmit={handleSubmit(onSubmit)}
          >
             <input
              type="hidden"
              value="110ac31a-7ebf-4244-b7a5-a42d21520aa5"
              {...register("access_key")}
            />
            <input
              type="hidden"
              value="Hunter Stone Law"
              {...register("from_name")}
            />
            <input
              type="checkbox"
              id=""
              className="hidden"
              style={{ display: "none" }}
              {...register("botcheck")}
            ></input>
              <div className="row">
                <div className="col-md-6 form-group">
                  <input
                    type="text"
                    {...register("name")}
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    className="form-control"
                    {...register("email")}
                    id="email"
                    placeholder="Your Email"
                    required
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  {...register("subject")}
                  id="subject"
                  placeholder="Subject"
                  required
                />
              </div>
              <div className="form-group mt-3">
                <textarea
                  className="form-control"
                  {...register("message")}
                  rows="5"
                  placeholder="Message"
                  required
                ></textarea>
              </div>
              <div className="my-3">
                {loading && <div className="loading">Loading</div>}
                {result && (
                  <div className={isSuccess ? "sent-message" : "error-message"}>
                    {result}
                  </div>
                )}
              </div>
              <div className="text-center mt-3">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
          <div className="col-lg-5" data-aos="fade-up" data-aos-delay="100">
            <div className="info-item d-flex">
              <i className="bi bi-geo-alt flex-shrink-0"></i>
              <div>
                <h4>Location:</h4>
                <p>
                  518 Roman Road, <br /> London,
                  <br /> E3 5ES
                </p>
              </div>
            </div>
            <div className="info-item d-flex">
              <i className="bi bi-envelope flex-shrink-0"></i>
              <div>
                <h4>Email:</h4>
                <p>info@hunterstonelaw.com</p>
              </div>
            </div>
            <div className="info-item d-flex">
              <i className="bi bi-phone flex-shrink-0"></i>
              <div>
                <h4>Call:</h4>
                <p>+44 20 3137 9097</p>
              </div>
            </div>
            <div className="info-item d-flex">
              <i className="bi bi-clock flex-shrink-0"></i>
              <div>
                <h4>Office Hours:</h4>
                <p>
                  Monday - Friday: 9:30 AM - 5:30 PM <br /> Saturday - Sunday:
                  Closed
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;