import React from "react";

const ServiceItem = ({ iconClass, title, description, delay }) => {
  return (
    <div className={`col-lg-4 col-md-6 service-item d-flex`} data-aos="fade-up" data-aos-delay={delay}>
      <div className="icon flex-shrink-0"><i className={iconClass} style={{ color: '#1086f4' }}></i></div>
      <div>
        <h4 className="title"><a href="#" className="stretched-link">{title}</a></h4>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};

const ServicesList = () => {
  return (
    <section id="services-list" className="services-list">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Services</h2>
        </div>
        <div className="row gy-5">
          <ServiceItem
            iconClass="bi bi-telephone"
            title="Telephone Advice"
            description="Get expert advice on immigration, asylum, nationality, and European law matters with our one-off telephone consultation. Speak directly to one of our experienced Senior Immigration Consultants to address your concerns and questions without the need for an in-person appointment. Receive personalized guidance without any written follow-up."
            delay="100"
          />
          <ServiceItem
            iconClass="bi bi-card-checklist"
            title="Application Checking Service"
            description="Ensure your application meets all requirements with our application checking service at Hunter Stone Law. Whether you've prepared it yourself or seeking reassurance, our consultants will review your documents and application to put your mind at ease before submission to the Home Office."
            delay="200"
          />
          <ServiceItem
            iconClass="bi bi-building"
            title="Corporate Services"
            description="Hunter Stone Law offers comprehensive corporate immigration services tailored to meet your business needs. From employee document checking to sponsor license acquisition and compliance services, we provide a full range of solutions. Contact our corporate immigration specialist for further assistance in advancing your business goals."
            delay="300"
          />
          <ServiceItem
            iconClass="bi bi-newspaper"
            title="Free Assessment"
            description="We provide a free assessment on your case where you have your documents scanned into electronic copies.  Please email us your details including your contact number and a brief background of your circumstances to info@hunterstonelaw.com. Please do not send any attachments with the email at this stage.  Once we have reviewed the information you have provided then will contact you by emailing you or calling you on the phone number provided in your email to discuss you further."
            delay="400"
          />
          <ServiceItem
            iconClass="bi bi-clipboard-data"
            title="Application Package"
            description="Our application package covers entry clearance and leave to remain in various immigration categories. It includes advising on options, gathering required documents, preparing and submitting applications, drafting representations, sending reminders, and providing progress updates until a decision is received. Fixed fees apply from initial instructions to Home Office/Embassy decision."
            delay="500"
          />
          <ServiceItem
            iconClass="bi bi-hand-thumbs-up"
            title="Fixed Fee Consultation"
            description="Our advice package provides fixed-fee consultations for clients seeking guidance on specific applications or personal circumstances. It includes advice on available options, relevant forms, required documents, fees, and expected outcomes. Clients can upgrade to an application or appeal package within agreed timeframes, with the fee deducted from the new package."
            delay="600"
          />
           <ServiceItem
            iconClass="bi bi-briefcase"
            title="Appeal Package (First - Tier Tribunal)"
            description="At Hunter Stone Law we can assist you if you have received a negative decision from the Home Office/Entry Clearance Officer and that the decision attracted a right of appeal to the First Tier Tribunal. First and foremost we assess the prospects of success, confirm it in writing and give you a clear balanced view of the possible outcome of you your case at appeal.  If you decide to proceed, then with our Appeal Package we provide advice and representation from the beginning till the end of your appeal at the First Tier Tribunal. We will consult with you in relation to your decision and lodge the Notice of Appeal.  We will then prepare a detailed bundle of evidence in relation to your case and in doing so, give your case the best chance of success.  We will then, either attend at the First Tier Tribunal and represent you at your hearing, or we will instruct an experienced barrister to represent you at your hearing.  We will ensure that your case is given the best possible chance of success."
            delay="700"
          />
        </div>
      </div>
    </section>
  );
};

export default ServicesList;
